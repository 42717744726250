exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nancy-js": () => import("./../../../src/pages/nancy.js" /* webpackChunkName: "component---src-pages-nancy-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-showreel-[name]-js": () => import("./../../../src/pages/showreel/[name].js" /* webpackChunkName: "component---src-pages-showreel-[name]-js" */),
  "component---src-pages-showreel-index-js": () => import("./../../../src/pages/showreel/index.js" /* webpackChunkName: "component---src-pages-showreel-index-js" */),
  "component---src-pages-showreel-showreel-yaml-slug-js": () => import("./../../../src/pages/showreel/{ShowreelYaml.slug}.js" /* webpackChunkName: "component---src-pages-showreel-showreel-yaml-slug-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

